@charset "utf-8";
//dark style

$darkbg: #232741;
$darkbgnew: #05549b;
$white: #ffffff;
$smoke: #dedede;
$brand-primary: #1e87e5;

$scheme-main: $darkbg;
$scheme-main-bis: #31375a;
$scheme-invert: $white;
$text: $smoke;
$text-strong: $white;
$primary: $brand-primary;
$link: $white;
$link-hover: $smoke;
$menu-label-color: $white;
$menu-item-active-background-color: $brand-primary;
$menu-item-active-color: $smoke;
$menu-item-hover-color: $smoke;
$menu-item-hover-background-color: $brand-primary;

@import "../../node_modules/bulma/bulma.sass";

html {
  scroll-behavior: auto;
}

//scrollbar

html::-webkit-scrollbar-track {
  //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #00000000;
}

html::-webkit-scrollbar {
  width: 5px;
  //background-color: #f5f5f5;
}

html::-webkit-scrollbar-thumb {
  background-color: $smoke;
  //border: 0px solid #555555;
}

.js-loading *,
.js-loading *:before,
.js-loading *:after {
  animation-play-state: paused !important;
}

.bgStyle {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background-color: #232741;
  /*background-image: url("/wordmark-white.svg");
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: 50% 50%*/
}

#particle-container {
  overflow: hidden;
}

.fullpagediv {
  height: 100%;
  width: 100%;
  background-color: $darkbgnew;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.halfpagediv {
  height: 50%;
  width: 100%;
  background-color: #ff0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.half-bg-style {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background-color: #232741;
  /*background-image: url("/wordmark-white.svg");
  background-repeat: no-repeat;
  background-size: 25%;
  background-position: 50% 50%*/
}

.home-anim {
  animation-name: slideupwordmark;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
  transform: translate(0, 10%);
}
.wwanim {
  animation-name: slideupwordmark;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
  transform: translate(0, 10%);
}
@keyframes slideupwordmark {
  from {
    transform: translate(0, 10%);
  }
  to {
    transform: translate(0, 0);
  }
}

//scale particlejs animation
#particle-container canvas {
  animation-name: appear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
}

@keyframes appear {
  from {
    transform: scale(2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.under-wordmark {
  position: absolute;
  margin: auto;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0%;
  width: 25%;
  pointer-events: all;
}

//scale wordmark animation
.primarywordmark {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  width: 25%;
  pointer-events: all;
}

.primarysubtitle {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  width: 25%;
  pointer-events: all;
}

.mainsubtitle {
  color: white;
  opacity: 0;
  animation-name: opacity;
  animation-delay: 2.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
  transform: translate(0, -10%);
}

.mainsubtitleother {
  color: white;
  opacity: 0;
  animation-name: opacity;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
  transform: translate(0, -10%);
}

@keyframes opacity {
  from {
    opacity: 0;
    transform: translate(0, -10%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.wordmarkwrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  width: 25%;
  pointer-events: all;
  height: fit-content;
}

@media (max-width: 768px) {
  /*.bgStyle {
      background-size: 80%
  }*/
  .wordmarkwrapper {
    width: 80%;
  }

  .board .columns div img {
    max-width: 100px;
  }
  .board .columns div.column.is-2 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .board .columns div.column.is-8 {
    padding-left: 24px;
  }
  .hero.is-medium .hero-body .columns .column {
    padding-left: 24px
  }

  .logospacer {
    display: none!important
  }

  .columns .column.is-offset-2 .content p {
    padding-left: 12px;
    padding-right: 12px;
  }

  .mobile-only {
    display: inherit!important;
  }
}

.mobile-only {
  display: none;
}

/*.zoomwordmark {
  animation-name: appear2, wordmarkslideup;
  animation-duration: 2s, 0.5s;
  animation-delay: 0s, 2.5s;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1), cubic-bezier(0, 1, 1, 1);
}*/

.zoomwordmark {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  animation-name: appear2;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1);
}

@keyframes appear2 {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/*@keyframes wordmarkslideup {
  from {
    transform: translate(0, 0%)
  }
  to {
    transform: translate(0, -50%)
  }
}*/

//invert wordmarks
.invert {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

#navlogo {
  max-height: 2rem;
}

//navbar
.nav-is-clear {
  background-color: #ffffff00;
}

.dark-star-gradient {
  background-image: linear-gradient(
    180deg,
    rgba(35, 39, 65, 0) 0%,
    rgba(35, 39, 65, 1) 100%
  );
  width: 100%;
  position: absolute;
  height: 25%;
  bottom: 0;
  pointer-events: none;
}

.down-button-container {
  width: 100%;
  position: absolute;
  height: 25%;
  bottom: 0;
  pointer-events: none;
}

#homedown {
  pointer-events: all;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: fadeIn, tugDown;
  animation-delay: 2s, 4s;
  animation-duration: 1s, 10s;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1), ease-out;
  opacity: 0;
  background-color: #00000000;
}

.down-arrow {
  pointer-events: all;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: fadeIn, tugDown;
  animation-delay: 2s, 4s;
  animation-duration: 1s, 10s;
  animation-iteration-count: 1, infinite;
  animation-fill-mode: forwards, forwards;
  animation-timing-function: cubic-bezier(0, 1, 1, 1), ease-out;
  opacity: 0;
  background-color: #00000000 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes tugDown {
  2.5% {
    transform: translate(0, 25%);
  }
  8% {
    transform: translate(0, 0);
  }
  10.5% {
    transform: translate(0, 25%);
  }
  18% {
    transform: translate(0, 0);
  }
}

.is-round {
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  border-radius: 9999px;
}

.tile-transparent {
  background-color: #00000000;
  box-shadow: 0 0 0 1pt $smoke;
}

//tiles
/*#student-tile {
  :hover {
    color: blue;
  }
}*/

.tile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #00000000;
  transition: background-color 0.2s, opacity 0.2s;
  &:hover {
    background-color: #00000040;
    opacity: 1;
  }
}

.overlay-parent {
  position: relative;
}

.overlay-column {
  //Put this class next to the overlay if there's a column with text
  margin-bottom: -12px !important;
}

.tile-hover-content {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#brownlogo {
  max-height: 64px; //was 140px
  width: auto;
}

.iconwrapper {
  padding: 0px;
  padding-top: 12px;
}

@media (max-width: 1000px) {
  #brownlogo {
    max-height: 64px;
    width: auto;
  }
}

.nopadding {
  padding: 0;
}

iframe {
  max-height: unset;
}

/*.ytvideo {
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}

.ytcontainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: blue;
  overflow: hidden;
}*/

.ytcontainer {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
  overflow: hidden;
  bottom: 0
}

.ytcontainer iframe, .video-container object, .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (print), (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0s !important;
    transition-duration: 0s !important;
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important; 
    animation-delay: 0s !important;
  }
}

.shadowimg {
  width: 150px;
  -webkit-filter: drop-shadow(5px 5px 5px #22222244);
  filter: drop-shadow(5px 5px 5px #22222244);
}

.hero.is-fullheight.mainhero {
  min-height: calc(100vh - 56px)
}